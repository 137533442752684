import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Checkbox, Progress } from '../../../components';
import ReactQuill from 'react-quill';
import { quillConfig } from '../../../helpers/formatters';
import Datetime from 'react-datetime';
import moment from 'moment';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';

class SubTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: this.props.tasks,

      editIndex: null,
      taskEdit: null,

      newTask: null
    };
  }

  onChange(index, name, value) {
    let { tasks } = this.state;
    tasks[index][name] = value;
    this.setState({ tasks });
  }

  edit(index) {
    let { tasks, editIndex, taskEdit } = this.state;
    taskEdit = cloneDeep(tasks[index]);
    editIndex = index;
    this.setState({ editIndex, taskEdit });
  }

  cancelEdit() {
    let { tasks, editIndex, taskEdit } = this.state;
    tasks[editIndex] = cloneDeep(taskEdit);
    taskEdit = null;
    editIndex = null;
    this.setState({ tasks, editIndex, taskEdit });
  }

  confirmEdit() {
    let { tasks, editIndex } = this.state;
    let index = cloneDeep(editIndex);
    let taskEdited = cloneDeep(tasks[editIndex]);

    this.setState({ editIndex: null, taskEdit: null }, () =>
      this.props.editSubtask(index, taskEdited)
    );
  }

  onChangeNewTask(name, value) {
    let { newTask } = this.state;
    newTask[name] = value;
    this.setState({ newTask });
  }

  addTask() {
    let { newTask } = this.state;
    let taskNew = cloneDeep(newTask);

    this.setState({ newTask: null }, () => this.props.addSubtask(taskNew));
  }

  render() {
    let { tasks, editIndex, newTask } = this.state;

    let progress =
      tasks && tasks.length
        ? (filter(tasks, { completed: true }).length * 100) / tasks.length
        : 0;

    return (
      <div>
        <Progress
          color="info"
          badge={<Trans>SubTasks</Trans>}
          value={progress}
        />
        <Button
          className="float-right"
          size="sm"
          color={'info'}
          disabled={editIndex !== null}
          onClick={() =>
            this.setState({ newTask: { name: '', completed: false } })
          }
        >
          <Trans>Add SubTask</Trans>
        </Button>

        <div className="table-full-width table-responsive">
          <table className="table">
            <tbody>
              {tasks.map((task, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <Checkbox
                        inputProps={{
                          checked: !!task.completed,
                          onChange: event =>
                            this.onChange(
                              key,
                              'completed',
                              event.target.checked
                            )
                        }}
                      />
                    </td>
                    <td className="text-left">
                      {key === editIndex ? (
                        <FormGroup>
                          <Input
                            type="text"
                            value={task.name}
                            onChange={event =>
                              this.onChange(key, 'name', event.target.value)
                            }
                          />
                        </FormGroup>
                      ) : (
                        task.name
                      )}
                    </td>
                    <td className="td-actions text-right">
                      {key === editIndex ? (
                        [
                          <Button
                            key="save"
                            className="btn-icon btn-neutral icon-action"
                            color="success"
                            onClick={() => this.confirmEdit()}
                          >
                            <i className="fas fa-save icon-action" />
                          </Button>,
                          <Button
                            key="cancel"
                            className="btn-icon btn-neutral icon-action"
                            color="default"
                            onClick={() => this.cancelEdit()}
                          >
                            <i className="fas fa-times icon-action" />
                          </Button>
                        ]
                      ) : (
                        <Button
                          className="btn-icon btn-neutral icon-action"
                          color="info"
                          disabled={editIndex !== null || newTask !== null}
                          onClick={() => this.edit(key)}
                        >
                          <i className="fas fa-pencil-alt icon-action" />
                        </Button>
                      )}
                      <Button
                        className="btn-icon btn-neutral icon-action"
                        color="danger"
                        disabled={editIndex !== null || newTask !== null}
                        onClick={() => this.props.deleteSubtask(key)}
                      >
                        <i className="fas fa-trash-alt icon-action" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {newTask ? (
                <tr>
                  <td />
                  <td className="text-left">
                    <FormGroup>
                      <Input
                        type="text"
                        value={newTask.name}
                        onChange={event =>
                          this.onChangeNewTask('name', event.target.value)
                        }
                      />
                    </FormGroup>
                  </td>
                  <td className="td-actions text-right">
                    <Button
                      className="btn-icon btn-neutral"
                      color="success"
                      onClick={() => this.addTask()}
                    >
                      <i className="fas fa-save icon-action" />
                    </Button>
                    <Button
                      className="btn-icon btn-neutral"
                      color="default"
                      onClick={() => this.setState({ newTask: null })}
                    >
                      <i className="fas fa-times icon-action" />
                    </Button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class TaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: {}
    };
  }

  componentWillMount() {
    let { task } = this.props;
    this.setState(ps => ({ ...ps, task }));
  }

  cleanModal() {
    this.setState({
      titleValid: null,
      descriptionValid: null,
      dueValid: null,
      reminderDateValid: null,
      task: {}
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { task } = this.state;

    this.setState({
      task: { ...task, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  checkDueDate() {
    let { task } = this.state;
    task.dueDate = task.dueDate ? null : moment();
    this.setState(ps => ({ ...ps, task }));
  }

  checkReminder() {
    let { task } = this.state;

    if (task.remember) {
      task.reminderDate = null;
      task.reminderEmail = null;
    } else {
      task.reminderDate = moment();
      task.reminderEmail = '';
    }
    task.remember = !task.remember;
    this.setState(ps => ({ ...ps, task }));
  }

  checkSubTasks() {
    let { task } = this.state;
    task.subTasks = task.subTasks ? null : [];
    this.setState(ps => ({ ...ps, task }));
  }

  editSubtask(index, taskEdited) {
    let { task } = this.state;

    if (task.subTasks[index]) {
      task.subTasks[index] = taskEdited;
    }

    this.setState({ task });
  }

  deleteSubtask(index) {
    let { task } = this.state;

    if (task.subTasks[index]) {
      task.subTasks.splice(index, 1);
    }

    this.setState({ task });
  }

  addSubtask(taskNew) {
    let { task } = this.state;

    if (!task.subTasks) {
      task.subTasks = [];
    }

    task.subTasks.push(taskNew);
    this.setState({ task });
  }

  validate() {
    let { task } = this.state;
    let title = this.title;
    let dueDate = this.dueDate;
    let reminderDate = this.reminderDate;

    let newState = {
      titleValid: title.validity.valid ? 'has-success' : 'has-danger',
      dueValid: task.dueDate
        ? dueDate && dueDate.validity.valid
          ? 'has-success'
          : 'has-danger'
        : 'has-success',
      reminderDateValid: task.remember
        ? reminderDate && reminderDate.validity.valid
          ? 'has-success'
          : 'has-danger'
        : 'has-success'
    };
    this.setState({ ...newState });

    return (
      title.validity.valid &&
      (task.dueDate ? dueDate && dueDate.validity.valid : true) &&
      (task.remember ? reminderDate && reminderDate.validity.valid : true)
    );
  }

  onSaveTask() {
    let { task } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(task);
    }
  }

  render() {
    let { task } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {task && task._id ? t('Edit Task') : t('New Task')}
        </ModalHeader>
        <ModalBody>
          {task ? (
            <Row>
              <Col xs={12} md={9}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.titleValid
                  }
                >
                  <Label>
                    <Trans>Title</Trans>
                  </Label>
                  <Input
                    type="text"
                    innerRef={node => (this.title = node)}
                    namevalid="titleValid"
                    value={task.title || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'title',
                        'titleValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>

                <FormGroup
                  className={
                    'has-label form-validation-40 ' +
                    this.state.descriptionValid
                  }
                >
                  <Label>
                    <Trans>Description</Trans>
                  </Label>
                  <ReactQuill
                    innerRef={node => (this.description = node)}
                    namevalid="descriptionValid"
                    value={task.description || ''}
                    modules={quillConfig}
                    className={'quill-text-editor'}
                    onChange={value =>
                      this.onChange(
                        'description',
                        'descriptionValid',
                        value,
                        true
                      )
                    }
                  />
                </FormGroup>

                {task.dueDate ? (
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.dueValid
                    }
                  >
                    <Label>
                      <Trans>Due Date</Trans>
                    </Label>
                    <Datetime
                      locale={'fr'}
                      value={moment(task.dueDate)}
                      inputProps={{
                        ref: node => (this.dueDate = node),
                        namevalid: 'dueValid',
                        className: 'form-control datetime-read-only',
                        required: 'required',
                        readOnly: true
                      }}
                      onChange={moment =>
                        this.onChange('dueDate', 'dueValid', moment, !!moment)
                      }
                    />
                  </FormGroup>
                ) : null}

                {task.remember ? (
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.reminderDateValid
                    }
                  >
                    <Label>
                      <Trans>Reminder Date</Trans>
                    </Label>
                    <Datetime
                      locale={'fr'}
                      value={moment(task.reminderDate)}
                      inputProps={{
                        ref: node => (this.reminderDate = node),
                        namevalid: 'reminderDateValid',
                        className: 'form-control datetime-read-only',
                        required: 'required',
                        readOnly: true
                      }}
                      onChange={moment =>
                        this.onChange(
                          'reminderDate',
                          'reminderDateValid',
                          moment,
                          !!moment
                        )
                      }
                    />
                  </FormGroup>
                ) : null}

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={!!task.completed}
                      onChange={event =>
                        this.onChange(
                          'completed',
                          'completedValid',
                          event.target.checked,
                          true
                        )
                      }
                    />
                    <span className="form-check-sign" />
                    <Trans>Completed</Trans>
                  </Label>
                </FormGroup>

                {task.subTasks ? (
                  <SubTask
                    tasks={task.subTasks}
                    editSubtask={(index, task) => this.editSubtask(index, task)}
                    deleteSubtask={index => this.deleteSubtask(index)}
                    addSubtask={newTask => this.addSubtask(newTask)}
                  />
                ) : null}
              </Col>
              <Col xs={12} md={3}>
                <h3 className="card-category">
                  <Trans>Add To Task</Trans>
                </h3>
                <div className="elements-task">
                  <div
                    className={`element-task ${
                      task.dueDate
                        ? 'element-task-selected'
                        : 'element-task-not-selected'
                    }`}
                    onClick={() => this.checkDueDate()}
                  >
                    <span>
                      <i className="far fa-calendar-alt icon-action mr-2" />
                    </span>
                    <Trans>Due Date</Trans>
                  </div>

                  <div
                    className={`element-task ${
                      task.remember
                        ? 'element-task-selected'
                        : 'element-task-not-selected'
                    }`}
                    onClick={() => this.checkReminder()}
                  >
                    <span>
                      <i className="far fa-bell icon-action mr-2" />
                    </span>
                    <Trans>Reminder</Trans>
                  </div>

                  <div
                    className={`element-task ${
                      task.subTasks !== null
                        ? 'element-task-selected'
                        : 'element-task-not-selected'
                    }`}
                    onClick={() => this.checkSubTasks()}
                  >
                    <span>
                      <i className="fas fa-list icon-action mr-2" />
                    </span>
                    <Trans>Subtasks</Trans>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveTask(task)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(TaskModal));
