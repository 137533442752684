import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { one, pdfCompound } from '../../../helpers/actions/projects';
import { PanelHeader, Spinner } from '../../../components';
import NavbarProject from '../NavbarActions/NavbarProject';

class DevisCompoundPrint extends Component {
  pages = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      project: {
        client: {
          phone: {}
        }
      }
    };
  }

  load() {
    this.setState({ loading: true });

    let { dispatch, match } = this.props;
    Promise.all([
      dispatch(
        one(match.params.id, {
          include: ['devis', 'devisBase'],
          fields: ['devis', 'devisBase', 'name', 'status']
        })
      )
    ])
      .then(([project]) => {
        dispatch(pdfCompound(project._id))
          .then(params => this.setState({ project, params, loading: false }))
          .catch(() => this.setState({ loading: false }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    let { loading, project, params } = this.state,
      { t } = this.props;

    let url = null,
      filename = `${t('Devis')}`;
    if (params) {
      url =
        params.url +
        '?apiRole=admin' +
        '&apiKey=' +
        params.httpHeaders.Authorization.replace('Bearer ', '');
    }

    if (project && project.name) {
      filename = `${t('Devis')} - ${project.name.replace(' ', '_')}.pdf`;
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <NavbarProject project={project} history={this.props.history} />
          <Card>
            <CardBody className="pdf-full">
              {url ? (
                <iframe title={filename} className="pdf-holder" src={url} />
              ) : null}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DevisCompoundPrint));
