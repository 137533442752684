import React, { Component } from 'react';
import { ButtonGroup, ButtonToolbar, Input } from 'reactstrap';
import { Button } from '../';

class Number extends Component {
  onIncrement() {
    let { value } = this.props;
    this.props.onChange((parseFloat(value) + 1).toFixed(2));
  }

  onDecrement() {
    let { value } = this.props;
    this.props.onChange((parseFloat(value) - 1).toFixed(2));
  }

  render() {
    let { value, step, min, max, required } = this.props;

    return (
      <ButtonToolbar>
        <ButtonGroup className={'number-component'}>
          <Button
            className={'btn-icon m-0'}
            round
            color="info"
            onClick={() => this.onDecrement()}
          >
            <i className="fa fa-minus icon-action" />
          </Button>
          <Input
            type="number"
            value={value}
            step={step}
            min={min}
            max={max}
            required={required}
            onChange={event => this.props.onChange(event.target.value)}
          />
          <Button
            className={'btn-icon m-0'}
            round
            color="info"
            onClick={() => this.onIncrement()}
          >
            <i className="fa fa-plus icon-action" />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    );
  }
}

export default Number;
